.wallet-option {
    display: flex;
    flex-direction: column;
    margin: var(--space32);
    align-items: center;
    cursor: pointer;
    transition: 150ms ease;
}

.wallet-option:hover {
    color: #3499de;
    transform: translateY(-2px);
}

.wallet-option:hover p {
    color: #3499de;
}

.screen-cover {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #3499de10;
    backdrop-filter: blur(3px);
}
