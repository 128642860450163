
.hover {
    transition: 150ms ease;
}

.hover:hover {
    transform: translateY(-3px);
}

.team-tile {
    width: 200px;
    height: 200px;
    border: 3px solid black;
    margin: 0 20px 16px 20px
}


.bg-face {
    left: -400px;
    top: 10px;
    transform: rotate(-3deg);
    width: 180px
}

.bg-face-mobile {
    left: -40px;
    top: 10px;
    transform: rotate(-3deg);
    width: 120px
}

.bg-face2 {
    right: -600px;
    top: 410px;
    transform: rotate(-30deg);
    width: 180px
}

.bg-face2-mobile {
    right: -60px;
    top: 320px;
    transform: rotate(-30deg);
    width: 160px
}


.bg-dots {
    right: -400px;
    top: 210px;
    transform: rotate(-3deg);
    width: 180px
}

.bg-sharp {
    left: -400px;
    top: -20px;
    transform: rotate(-3deg);
    width: 290px
}

.bg-horns {
    right: -40px;
    top: -120px;
    transform: rotate(-163deg);
    width: 180px
}

.bg-arrow {
    right: -420px;
    top: 40px;
    transform: rotate(13deg);
    width: 40px
}

.bg-free {
    left: 20px;
    top: -30px;
    transform: rotate(-32deg);
    width: 120px
}


.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
    z-index: -1;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

.floating2 {
    animation-name: floating2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
    z-index: -1;
}

@keyframes floating2 {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

/*.floating2 {*/
/*    animation-name: floating2;*/
/*    animation-duration: 3s;*/
/*    animation-delay: 1s;*/
/*    animation-iteration-count: infinite;*/
/*    animation-timing-function: ease-in-out;*/
/*    margin-left: 30px;*/
/*    margin-top: 5px;*/
/*    z-index: -1;*/
/*}*/

/*@keyframes floating2 {*/
/*    0% { transform: translate(0,  -10px); }*/
/*    50%  { transform: translate(0, 20px); }*/
/*    100%   { transform: translate(0, -10px); }*/
/*}*/

.spinner {
    height: 32px;
    width: 24px;
    border: 2px solid #FFFFFF20;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: #3499de20;
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

.pointer {
    cursor: pointer !important;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
