
body {
    font-family: 'Gloria Hallelujah', cursive;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

p {
    /*font-size: 20px;*/
}

.body1 {
    font-size: 16px;
}


.headline {
    font-size: 81px;
    line-height: 0;
}

.headline2 {
    font-size: 81px;
}

.headline-sm {
    font-size: 52px;
    line-height: 50px;
}

.title {
    font-size: 48px;
}

.title2 {
    font-size: 38px;
}

.title3 {
    font-size: 30px;
}

.o8 {
    opacity: .8;
}

.o5 {
    opacity: .5;
}

